import Configuration from './config';

function generateNonce(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

const getParams = url => {
    var regex = /[#?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
        
    while (match = regex.exec(url)) {
        params[match[1]] = match[2];
    }

    return params;
}

export const processAuthResults = url => {
    let params = getParams(url);

    let expiresAt = JSON.stringify((params['expires_in'] * 1000) + new Date().getTime());
    let acToken = params['access_token'];
    let idToken = params['id_token'];

    localStorage.setItem('access_token', acToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);
    
    let state = decodeURIComponent(params['state']);
    return state;
}

export function getAuthUrl(originalUrl) {
    var encodedOriginal = encodeURI(originalUrl);
    let nonce = generateNonce(32);

    return Configuration.authorizationEndpoint +
        '?response_type=id_token%20token' +
        '&client_id=' + Configuration.clientId +
        '&scope=' + Configuration.scopes +
        '&redirect_uri=' + Configuration.redirectUri +
        '&nonce=' + nonce +
        '&state=' + encodedOriginal;
}

export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
}

export function isAuthenticated() {
    let expireTime = localStorage.getItem('expires_at');
    let expiresAt = JSON.parse(expireTime);
    return new Date().getTime() < expiresAt;
}

export const getAuthToken = () => {

    if(isAuthenticated() === true) {
      
        let token = localStorage.getItem('access_token');
        return token;
    }
    else {
       
        window.location = getAuthUrl(window.location.pathname)
    }
}