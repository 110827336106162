const LOADING_DATA = 'LOADING_DATA'
const LOADED_DATA = 'LOADED_DATA'
const LOADING_DATA_FAILED = 'LOADING_DATA_FAILED'
const HOME_SEARCH_TEXT_CHANGE = 'HOME_SEARCH_TEXT_CHANGE'
const LOG_LOAD = 'LOG_LOAD'

export const Home = {
    LOADING_DATA,
    LOADED_DATA,
    LOADING_DATA_FAILED,
    HOME_SEARCH_TEXT_CHANGE,
    LOG_LOAD
} 


const SELECT_COMPANY = 'SELECT_COMPANY'
const SELECT_AREA = 'SELECT_AREA'
const LOADING_FILTERS = 'LOADING_FILTERS'
const LOADED_FILTERS = 'LOADED_FILTERS'
const LOADING_FILTERS_FAILED = 'LOADING_FILTERS_FAILED'

export const Filters = {
    LOADED_FILTERS,
    LOADING_FILTERS,
    LOADING_FILTERS_FAILED,
    SELECT_AREA,
    SELECT_COMPANY
}