import {Filters} from '../actions/action_types'

const initialState = {
    area: 'All',
    company: localStorage.getItem('userCompany'),
    areas: [],
    companies: [], 
    loaded: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Filters.LOADING_FILTERS:
            return Object.assign({}, state, { loaded: action.payload })

        case Filters.LOADED_FILTERS:
            return Object.assign({}, state, { loaded: true }, action.payload)
        
        case Filters.LOADING_FILTERS_FAILED:
            return Object.assign({}, state, { loaded: false })

        case Filters.SELECT_AREA:
        case Filters.SELECT_COMPANY:
            return Object.assign({}, state, action.payload)
        
        default: 
            return state
    }
}