import {Filters} from './action_types'
import {get, filters} from '../utils/https'

export const getFilters = () => {
    return async (dispatch) => {
        dispatch({ type: Filters.LOADING_FILTERS, payload: undefined })

        get(filters, (response, error) => {
            if (error || response.statusCode !== 200) dispatch({ type: Filters.LOADING_FILTERS_FAILED, payload: null })
            else dispatch({ type: Filters.LOADED_FILTERS, payload: response.data })
        })

    }
}

export const setArea = (area) => {
    return { type: Filters.SELECT_AREA, payload: { area } }
} 

export const setCompany = (company) => {
    return { type: Filters.SELECT_COMPANY, payload: { company } }
} 