import React from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Select, Badge, Layout, Input, Menu, Dropdown, Tooltip, Spin, Card} from 'antd'
import {FilterFilled , PlusSquareFilled , AntCloudOutlined, RightOutlined,FontColorsOutlined} from '@ant-design/icons'
import Logo from './../../assests/images/Logo.png'
const {Header, Content, Footer} = Layout
const {Search} = Input
const {Option} = Select

const menu = (handleClick, current, areas) => {
	return (
		<Menu onClick={handleClick} selectedKeys={[current]}>
			<Menu.Item key='All'>
				<p>All</p>
			</Menu.Item>
			{areas.map(area => {
				return (
					<Menu.Item key={area}>
						<p>{area}</p>
					</Menu.Item>
				)
			})}
		</Menu>
	)
}

export default ({areas, companies, acronyms, area, company, searchText, onAreaSelect, onCompanySelect, onSearchTextChange, onClickDefinition, history,loading}) => {
	return (
		<Layout>
			
			<Header className={company} id="Home_header" style={{position: 'fixed', padding: '0 10px', zIndex: 1, width: '100%', boxShadow: '0 4px 8px 0 rgba(33,43,54,.09)', borderBottom: '1px solid #cfcfcf'}}>
				<Row justify='space-between' align="middle">
					<Col xs={4} sm={4} md={3} lg={3} xl={3}>
					<div style={{display:'flex', alignItems:'center'}}>
						<FontColorsOutlined style={{color: 'white', fontSize:'35px'}} />
							<span className='logoTitlte'>
								cronyms
							</span>
						</div>
					</Col>
					<Col xs={0} sm={0} md={0} lg={6} xl={6}></Col>
					<Col xs={8} sm={8} md={10} lg={8} xl={8}>
						<div style={{display:"flex"}}>
							<Search placeholder='Search' value={searchText} onChange={event => onSearchTextChange(event.target.value)} />
						</div>
					</Col>

					<Col xs={6} sm={6} md={4} lg={4} xl={4}>
						<div className='company_filter' style={{textAlign: 'center'}}>
							<Tooltip placement='left' title='Filter Company'>
								<Select defaultValue={company} className="company_Filter_height"  style={{width: '100%'}} placeholder="Select Company" value={company} onChange={event => onCompanySelect(event)}>
									{companies.map(_company => {
										return (
											<Option style={{textAlign:'center'}} key={_company} value={_company}>
												<img style={{width:'50%'}} src={require(`./../../assests/images/${_company}.svg`)}/>
												
											</Option>
										)
									})}
								</Select>
							</Tooltip>
						</div>
					</Col>
					<Col xs={2} sm={2} md={2} lg={1} xl={1}>
						<div style={{textAlign: 'center'}}>
							<Tooltip placement='left' title='Filter Area'>
								<Dropdown placement='bottomLeft' overlayClassName='overlaydrop' getPopupContainer={trigger => trigger.parentNode} overlay={menu(onAreaSelect, area, areas)} trigger={['click']}>
									<a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
										<Badge dot={area === 'All' ? false : true}>
											<FilterFilled  style={{color: 'white', fontSize: '18px'}} />
										</Badge>
									</a>
								</Dropdown>
							</Tooltip>
						</div>
					</Col>
					<Col xs={2} sm={2} md={2} lg={1} xl={1}>
						<div style={{textAlign: 'center'}}>
							<Tooltip placement='left' title='Add Acronym'>
								<Link to={`/add_acronym`}>
									<PlusSquareFilled  style={{color: 'white', fontSize: '18px'}} />
								</Link>
							</Tooltip>
						</div>
					</Col>
				</Row>
			</Header>
			<Content className='site-layout Home_content' style={{ background: 'white'}}>
				{
					loading ? <div className='loader_full_screen'>
					 	<Spin />
					 </div> : (
						<div>
							<Row align='middle' justify='center'>
								<Col xs={24} sm={24} md={24} lg={24} xl={24}>
									{Object.keys(acronyms).length === 0 ? (
										<div style={{height: '90vh'}}>
											<div className='noData'>
												<AntCloudOutlined style={{fontSize: '100px'}} />
												<h1 style={{color: 'rgb(227, 5, 54)'}}>Oops! No Data For Selected Filters</h1>
											</div>
										</div>
									) : (
										acronyms &&
										Object.keys(acronyms).map(area => {
											return (
												<React.Fragment>
													<div className='A_area'>
														<h1>{area}</h1>
													</div>
													<React.Fragment>
														{acronyms[area].map(single => {
															return (
																<div className='single_Card' onClick={() => onClickDefinition(single)}>
																	<Row>
																		<Col xs={24} sm={24} md={3} lg={2} xl={2}>
																			<p className='abbr'>{single.abbreviation}</p>
																		</Col>
																		<Col xs={24} sm={24} md={21} lg={22} xl={22}>
																			<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
																				<p className='def'>{single.definition}</p>
																				<RightOutlined style={{marginRight: '5px'}} />
																			</div>
																		</Col>
																	</Row>
																</div>
															)
														})}
													</React.Fragment>
												</React.Fragment>
											)
										})
									)}
								</Col>
							</Row>
						</div>
					)
				}
				
			</Content>
		</Layout>
	)
}
