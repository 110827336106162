import { combineReducers } from 'redux';

import home from './home_reducer'
import filters from './filters_reducer'


const rootReducer = combineReducers({
    home,
    filters
})

export default rootReducer;