import { post ,note} from "../../utils/https";
import {Spin,message} from 'antd'

const analytics = {
	timestamp: Date.now(),
	company: localStorage.getItem('userCompany'),
	firstName:localStorage.getItem('firstName'),
	lastName:localStorage.getItem('lastName'),
	networkID:localStorage.getItem('networkID'),
	email:localStorage.getItem('sub'),
	title:localStorage.getItem('title'),
}

export const noted = (actionType,eventDetails) => {
    const bodyRequest = {
        ... analytics,
        action : actionType,
        eventDetails
    }
  
    post(note, bodyRequest, (response, error) => {
        if (error) {
            // message.error('Sorry Something Went Wrong ...')
        } else {
            // message.success('Sorry Everything Went Right ...')
        }
    })
}
