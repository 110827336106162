import React, {Component} from 'react'
import {connect} from 'react-redux'
import Home from './home'
import {Spin,message} from 'antd'
import {getData, onSearchTextChange,onLogLoad} from './../../actions/home_actions'
import {getFilters, setArea, setCompany} from './../../actions/filter_actions'
import {noted} from './../analytics/analytics'

const mapStateToProps = state => {
	return {
		filters: state.filters,
		home: state.home
	}
}
const mapDispatchToProps = {
	onSearchTextChange,
	getFilters,
	setArea,
	getData,
	setCompany,
	onLogLoad
}

class HomeListing extends Component {
	
	componentDidMount = () => {
	
		if(this.props.filters.company === null) {
			this.props.setCompany(localStorage.getItem('userCompany')) 
		}
		if (this.props.home.loaded === false) {
			this.props.getData(this.props.filters.company ?? localStorage.getItem('userCompany'))
		}
		
		if (this.props.filters.loaded === false) {
			this.props.getFilters()
		}

		if (this.props.filters.logLoad === false){
			this.props.onLogLoad(true)
			const eventDetails = {
			
			}
			noted("logged_on",eventDetails)
		}


	}

	onClickDefinition = single => {
		this.props.history.push({
			pathname: '/view_acronym',
			data: single, // your data array of objects
		})
		const eventDetails = {
            company: single.company,
            acronym: single.abbreviation
        }
		noted("acronym_select",eventDetails)
		
	}

	onSearchTextChange = searchText => this.props.onSearchTextChange(searchText)
	onAreaSelect = e => {
		this.props.setArea(e.key)
		const eventDetails = {
			area: e.key,
			company: this.props.filters.company,
			
		}
		noted("area_select",eventDetails)
	}
	
	onCompanySelect = company => {
		this.props.getData(company)
		this.props.setCompany(company)
		const eventDetails = {
			company: company,
		}
		noted("company_select",eventDetails)
	}

	render = () => {
	// 	if (!this.props.home.loaded || !this.props.filters.loaded) return <div className='loader_full_screen'>
	// 	<Spin />
	// </div>

		return <Home
			loading={!this.props.home.loaded || !this.props.filters.loaded}
			areas={this.props.filters.areas}
			companies={this.props.filters.companies} 
			acronyms={this.filteredData()}
			area={this.props.filters.area}
			company={this.props.filters.company} 
			searchText={this.props.home.searchText}
			onAreaSelect={this.onAreaSelect} 
			onCompanySelect={this.onCompanySelect}
			onSearchTextChange={this.onSearchTextChange}
			onClickDefinition={this.onClickDefinition}
			history={this.props.history} />
	}

	filteredData = () => {
		const rawData = this.props.home.acronyms
		const selectedArea = this.props.filters.area
		const searchText = this.props.home.searchText
		const matched_data = {}

		if (selectedArea !== 'All') { // Area selected
			const abbreviations = rawData[selectedArea]
			if(abbreviations){
				const matched = []
				abbreviations.forEach(abbr => {
				if (abbr.definition.toLowerCase().includes(searchText.toLowerCase()) || abbr.abbreviation.toLowerCase().includes(searchText.toLowerCase())) {
					matched.push(abbr)
				}
			})
			matched_data[selectedArea] = matched
			}

		} else { // !selected
			for (const [key, value] of Object.entries(rawData)) {
				const matched = []
				value.forEach(abbr => {
					if (abbr.definition.toLowerCase().includes(searchText.toLowerCase()) || abbr.abbreviation.toLowerCase().includes(searchText.toLowerCase())) {
						matched.push(abbr)
					}
				})
				if (matched.length > 0) matched_data[key] = matched
			}
		}
		return matched_data
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeListing)
