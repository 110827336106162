import React from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom'
import './App.scss';

// Routes.
import Root from './components/index'
import Redirect from './components/redirect'
import AddAcronym from './components/add_Aronym/index'
import ViewAcronym from './components/view_Aronym/index'

function App() {
  return (
    <BrowserRouter>
			<Switch>
				<Route exact path='/' component={Root} />
				<Route path={'/redirect'} component={Redirect} />
				<Route path={'/add_acronym'} component={AddAcronym} />
				<Route path={'/view_acronym'} component={ViewAcronym} />
			</Switch>
		</BrowserRouter>
  );
}

export default App;
