import {Home} from './../actions/action_types'

const initialState = {
	loaded: false,
	acronyms: {},
	searchText: '',
	logLoad: false
} 

export default (state = initialState, action) => {
	switch (action.type) {
		case Home.SELECT_COMPANY:
			return Object.assign({}, state, {selectedCompany: action.payload})

		case Home.SELECT_AREA:
			return Object.assign({}, state, {selectedArea: action.payload})

			case Home.LOADING_DATA:
				return Object.assign({}, state, { loaded: action.payload })
	
			case Home.LOADED_DATA:
				return Object.assign({}, state, { loaded: true, acronyms: action.payload })
			
			case Home.LOADING_DATA_FAILED:
				return Object.assign({}, state, { loaded: false })

			case Home.HOME_SEARCH_TEXT_CHANGE:
				return Object.assign({}, state, action.payload)

			case Home.LOG_LOAD:
				return Object.assign({}, state, action.payload)

		default:
			return state
	}
}
