import { Home } from './action_types'
import {get,abbreviations} from '../utils/https'

export const getData = (selectedCompany) => { 
    return async (dispatch) => {
        dispatch({ type: Home.LOADING_DATA, payload: undefined })
        get(abbreviations + `/${selectedCompany}`, (response, error) => {
            if (error || response.statusCode !== 200) dispatch({ type: Home.LOADING_DATA_FAILED, payload: null })
            else dispatch({ type: Home.LOADED_DATA, payload: response.data })
        })
    }
}

export const onSearchTextChange = (searchText) => {
    return { type: Home.HOME_SEARCH_TEXT_CHANGE, payload: { searchText } }
}

export const onLogLoad = (logLoad) => {
    return {type: Home.LOG_LOAD, payload: {logLoad}}
}