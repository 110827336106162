import React from 'react'
import {Link} from 'react-router-dom'
import {Row, Col, Button, Layout, Input, Menu, Dropdown, Tooltip, Descriptions, Spin} from 'antd'
import {EditFilled, SaveFilled,CloseOutlined} from '@ant-design/icons'
import CommonHeader from './../common_header/commonHeader'
const {Header, Content, Footer} = Layout
const {TextArea} = Input

export default ({onClickEdit, gcompany,edit, history,Alldetails, onacronymDefinition, onClickCancel, aDefinition, aDescription, loading, onacronymDescription, onSave}) => {
	return ( 
		<Layout>
			<CommonHeader />
			{loading === true && (
				<div className='loader_full_screen'>
					<Spin />
				</div>
			)}
			<Content className='site-layout' style={{marginTop: 55, background: 'white'}}>
				<div className='Add_Acronym'>
					{/* <Descriptions title='' bordered column={{xxl: 4, xl: 2, lg: 2, md: 1, sm: 1, xs: 1}}>
						<Descriptions.Item label='Acronym Area'>{Alldetails.area}</Descriptions.Item>
						<Descriptions.Item label='Acronym'>{Alldetails.abbreviation}</Descriptions.Item>
						<Descriptions.Item label='Definition'>{edit === true ? <TextArea allowClear={true} rows={4} onChange={onacronymDefinition} value={aDefinition} /> : Alldetails.definition}</Descriptions.Item>
						<Descriptions.Item label='Description'>{edit === true ? <TextArea allowClear={true} rows={4} onChange={onacronymDescription} value={aDescription} /> : Alldetails.description}</Descriptions.Item>
						<Descriptions.Item label='Created By'>{Alldetails.createdByfirstName} {Alldetails.createdBylastName}</Descriptions.Item>
						<Descriptions.Item label='Created On'>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(Alldetails.createdOn)}</Descriptions.Item>
						<Descriptions.Item label='Changed By'>{Alldetails.updatedBy === null ? '-' : Alldetails.updatedByfirstName}</Descriptions.Item>
						<Descriptions.Item label='Changed On'>{Alldetails.updatedOn === null ? '-' : new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(Alldetails.updatedOn)}</Descriptions.Item>
						<Descriptions.Item label='Company'>{Alldetails.company}</Descriptions.Item>
					</Descriptions> */}
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Acronym Area:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{Alldetails.area}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Acronym:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{Alldetails.abbreviation}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Definition:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{ <TextArea allowClear={true} disabled={edit === true ? false : true} rows={4} onChange={onacronymDefinition} value={aDefinition} /> }</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Description:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{ <TextArea allowClear={true}  disabled={edit === true ? false : true} rows={4} onChange={onacronymDescription} value={aDescription} />}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Company:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{Alldetails.company}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Created By:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							{/* <p className='detail'>{Alldetails.createdByFirstName} {Alldetails.createdByLastName}</p> */}
				<p className='detail'>{Alldetails.createdByFirstName === null ? Alldetails.createdBy : `${Alldetails.createdByFirstName} ${Alldetails.createdByLastName}`}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Created On:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(Alldetails.createdOn)}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Changed By:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							{/* <p className='detail'>{Alldetails.updatedBy === null ? '-' : `${Alldetails.updatedByFirstName} ${Alldetails.updatedByLastName}`}</p> */}
							<p className='detail'>{Alldetails.updatedBy === null ? '-' : Alldetails.updatedByFirstName === null ? Alldetails.updatedBy : `${Alldetails.updatedByFirstName} ${Alldetails.updatedByLastName}`}</p>
						</Col>
					</Row>
					<Row justify='center' align='middle'>
						<Col xs={22} sm={22} md={4} lg={3} xl={3}>
							<div className='View_single_input' style={{width: '100%'}}>
								<p>Changed On:</p>
							</div>
						</Col>
						<Col xs={22} sm={22} md={12} lg={10} xl={10}>
							<p className='detail'>{Alldetails.updatedOn === null ? '-' : new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(Alldetails.updatedOn)}</p>
						</Col>
					</Row>

					<Row justify='center'>
						<Col xs={22} sm={22} md={20} lg={10} xl={10}>
							<div style={{display: 'flex', justifyContent: 'space-around', margin: '30px 0'}}>
								<Button className={gcompany} shape="round" type='primary common_btn'  onClick={() => onClickEdit()} icon={edit === false ? <EditFilled /> : <CloseOutlined />}>
									{edit === false ? "Edit" : "Cancel"}
								</Button>
								{ edit === true ? <Button className={gcompany} shape="round" onClick={() => onSave()} type='primary common_btn'  icon={<SaveFilled />}>
									Save
								</Button> : null
								}
								{/* <Button onClick={() => onSave()} type='primary common_btn' disabled={edit === false ? true : false} icon={<SaveFilled />}>
									Save
								</Button> */}
							</div>
						</Col>
					</Row>
				</div>
			</Content>
		</Layout>
	)
}
