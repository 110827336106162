import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Layout,
  Input,
  Menu,
  Dropdown,
  Tooltip,
  Select,
  Spin,
} from "antd";
import { CloseCircleFilled, SaveFilled } from "@ant-design/icons";
import CommonHeader from "./../common_header/commonHeader";
const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

export default ({
  onSave,
  gcompany,
  onacronymName,
  history,
  onSelectCompany,
  company,
  onacronymDefinition,
  loading,
  onacronymDescription,
  onSelectArea,
  area,
  acronymName,
  aDefinition,
  aDescription,
  areas,
}) => {
  console.log(areas);
  return (
    <Layout>
      <CommonHeader />
      <Content
        className="site-layout"
        style={{ marginTop: 55, background: "white" }}
      >
        {loading === true && (
          <div className="loader_full_screen">
            <Spin />
          </div>
        )}
        <div className="Add_Acronym">
          <h1>Add Acronym</h1>
          <Row justify="center" style={{ margin: "10px 0" }}>
            <Col xs={22} sm={22} md={4} lg={3} xl={3}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <p>
                  <span>*</span>Acronym Area:
                </p>
              </div>
            </Col>

            <Col xs={22} sm={22} md={20} lg={10} xl={10}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Area"
                  value={area}
                  onChange={(event) => onSelectArea(event)}
                >
                  {areas.map((area) => {
                    return <Option value={area}>{area}</Option>;
                  })}
                </Select>
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: "10px 0" }}>
            <Col xs={22} sm={22} md={4} lg={3} xl={3}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <p>
                  <span>*</span>Acronym Company:
                </p>
              </div>
            </Col>

            <Col xs={22} sm={22} md={20} lg={10} xl={10}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Company"
                  value={company}
                  onChange={(event) => onSelectCompany(event)}
                >
                  <Option value="Flint Hills Resources">
                    Flint Hills Resources
                  </Option>
                  <Option value="Georgia Pacific">Georgia Pacific</Option>
                  <Option value="Guardian Industries">
                    Guardian Industries
                  </Option>
                  <Option value="Infor">Infor</Option>
                  <Option value="Invista">Invista</Option>
                  <Option value="Molex">Molex</Option>
                  <Option value="Koch Technology Center">
                    Koch Technology Center
                  </Option>
                  <Option value="Koch Ag & Energy Solutions">
                    Koch Ag & Energy Solutions
                  </Option>
                  <Option value="Koch Engineered Solutions">
                    Koch Engineered Solutions
                  </Option>
                  <Option value="Koch Disruptive Technologies">
                    Koch Disruptive Technologies
                  </Option>
                  <Option value="Koch Equity Development">
                    Koch Equity Development
                  </Option>
                  <Option value="Koch Minerals">Koch Minerals</Option>
                  <Option value="Koch Supply & Trading">
                    Koch Supply & Trading
                  </Option>
                  <Option value="Matador">Matador</Option>
                </Select>
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: "10px 0" }}>
            <Col xs={22} sm={22} md={4} lg={3} xl={3}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <p>
                  <span>*</span>Acronym:
                </p>
              </div>
            </Col>
            <Col xs={22} sm={22} md={20} lg={10} xl={10}>
              <div className="Add_single_input">
                <Input
                  placeholder="Add New Acronym"
                  onChange={onacronymName}
                  value={acronymName}
                />
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: "10px 0" }}>
            <Col xs={22} sm={22} md={4} lg={3} xl={3}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <p>
                  <span>*</span>Definition:
                </p>
              </div>
            </Col>
            <Col xs={22} sm={22} md={20} lg={10} xl={10}>
              <div className="Add_single_input">
                <TextArea
                  rows={4}
                  placeholder="Add Definition"
                  onChange={onacronymDefinition}
                  value={aDefinition}
                />
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{ margin: "10px 0" }}>
            <Col xs={22} sm={22} md={4} lg={3} xl={3}>
              <div className="Add_single_input" style={{ width: "100%" }}>
                <p>Description: </p>
              </div>
            </Col>
            <Col xs={22} sm={22} md={20} lg={10} xl={10}>
              <div className="Add_single_input">
                <TextArea
                  rows={4}
                  placeholder="Add Description"
                  onChange={onacronymDescription}
                  value={aDescription}
                />
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={22} sm={22} md={20} lg={10} xl={10}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "30px 0",
                }}
              >
                <Button
                  className={gcompany}
                  type="primary common_btn"
                  shape="round"
                  onClick={() => onSave()}
                  icon={<SaveFilled />}
                >
                  Save
                </Button>
                <Link to={`/`}>
                  <Button
                    className={gcompany}
                    type="primary common_btn"
                    shape="round"
                    icon={<CloseCircleFilled />}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};
