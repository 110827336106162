import React, { Component } from "react";
import { connect } from "react-redux";
import Add from "./addAcronym";
import { Row, message } from "antd";
import { post, abbreviations } from "../../utils/https";
import { noted } from "./../analytics/analytics";

const mapStateToProps = (state) => {
  return {
    company: state.filters.company,
    filters: state.filters,
  };
};
const mapDispatchToProps = {};

class AddAcronym extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acronymName: undefined,
      aDefinition: undefined,
      aDescription: undefined,
      area: undefined,
      company: "Molex",
      loading: false,
    };
  }

  onacronymName = (e) => {
    this.setState({ acronymName: e.target.value.toUpperCase() });
  };
  onacronymDefinition = (e) => {
    this.setState({ aDefinition: e.target.value });
  };
  onacronymDescription = (e) => {
    this.setState({ aDescription: e.target.value });
  };

  onSelectArea = (area) => {
    this.setState({ area });
  };
  onSelectCompany = (company) => {
    this.setState({ company });
  };

  validateObject = () => {
    const { acronymName, aDefinition, area, company } = this.state;

    // For Apps
    if (company === "" || company === undefined) {
      message.error("Select Acronym Company");
      return false;
    }
    if (area === "" || area === undefined) {
      message.error("Select Acronym Area");
      return false;
    }
    if (acronymName === "" || acronymName === undefined) {
      message.error("Enter Acronym");
      return false;
    }
    if (aDefinition === "" || aDefinition === undefined) {
      message.error("Enter Acronym Definition");
      return false;
    }

    return true;
  };

  onSave = () => {
    if (this.validateObject() === true) {
      const bodyRequest = {
        abbreviation: this.state.acronymName,
        definition: this.state.aDefinition,
        description: this.state.aDescription,
        area: this.state.area,
        company: this.state.company,
      };

      this.setState({ loading: true });
      post(abbreviations, bodyRequest, (response, error) => {
        this.setState({ loading: false });
        if (error) {
          message.error("Sorry Something Went Wrong ...");
          const eventDetails = {
            type: "acronym_add",
            error: error,
          };
          noted("error", eventDetails);
        } else {
          const eventDetails = {
            company: bodyRequest.company,
            acronym: bodyRequest.abbreviation,
          };
          noted("acronym_add", eventDetails);
          message.success("Acronym Added.");
          this.setState({
            acronymName: undefined,
            aDefinition: undefined,
            aDescription: undefined,
            area: undefined,
            company: undefined,
          });
          this.props.history.push({
            pathname: "/",
          });
        }
      });
    }
  };

  render = () => {
    console.log(this.props.filters.areas);
    return (
      <Add
        {...this.state}
        gcompany={this.props.company}
        onSave={this.onSave}
        history={this.props.history}
        onSelectCompany={this.onSelectCompany}
        onSelectArea={this.onSelectArea}
        onacronymName={this.onacronymName}
        onacronymDefinition={this.onacronymDefinition}
        onacronymDescription={this.onacronymDescription}
        areas={this.props.filters.areas}
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAcronym);
