import React, {Component} from 'react'
import {connect} from 'react-redux'
import View from './viewAcronym'
import {Row, Col, message} from 'antd'
import {Redirect} from 'react-router-dom'
import {get, abbreviations, put} from '../../utils/https'
import {noted} from './../analytics/analytics'

const mapStateToProps = state => { 
	return {
		company: state.filters.company
	}
}
const mapDispatchToProps = {}

class ViewAcronym extends Component {
	componentDidMount = () => {
		const all = this.props.location.data
	
	}
	constructor(props) {
		super(props)
		
		this.state = {
			edit: false,
			loading: false,
			aDefinition: this.props.location.data ? this.props.location.data.definition : undefined,
			aDescription: this.props.location.data ? this.props.location.data.description : undefined,
			aPreviousDef: this.props.location.data ? this.props.location.data.definition : undefined,
			aabbreviation: this.props.location.data ? this.props.location.data.abbreviation : undefined,
			acompany: this.props.location.data ? this.props.location.data.company : undefined,

		}
	}

	onClickEdit = () => {
		this.setState({
			edit: !this.state.edit
		  });
	}
	
	onClickCancel = () => {
		this.setState({edit: false})
	}
	onacronymDefinition = e => {
		this.setState({aDefinition: e.target.value})
	}
	onacronymDescription = e => {
		this.setState({aDescription: e.target.value})
	}

	validateObject = () => {
		const {aDefinition, aDescription} = this.state

		if (aDefinition === '' || aDefinition === undefined) {
			message.error('Enter A Dif')
			return false
		}
		return true
	}

	onSave = () => {
		if (this.validateObject() === true) {
			const bodyRequest = {
				abbrId: this.props.location.data.abbrId,
				definition: this.state.aDefinition,
				description: this.state.aDescription,
				previousDefinition: this.state.aPreviousDef
			}
			
			this.setState({loading: true})
			put(abbreviations, bodyRequest, (response, error) => {
				this.setState({loading: false})
				if (error) {
					message.error('Sorry Something Went Wrong ...')
					const eventDetails = {
						type: "acronym_update",
						error: error
					}
					noted("error",eventDetails)
				} else {
					const eventDetails = {
						company: this.state.aabbreviation,
						acronym: this.state.acompany
					}
					noted("acronym_update",eventDetails)
					message.success('Acronym Updated.')
					this.setState({
						edit: false,
					})
					this.props.history.push({
						pathname: '/',
					})
				}
			})
		}
	}

	render = () => {
		if (!this.props.location.data) return <Redirect to={'/'} />
		return <View {...this.state} gcompany={this.props.company} Alldetails={this.props.location.data} history={this.props.history} onClickCancel={this.onClickCancel} onSave={this.onSave} onacronymDescription={this.onacronymDescription} onacronymDefinition={this.onacronymDefinition} onClickEdit={this.onClickEdit} />
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAcronym)
