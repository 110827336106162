import React from 'react'
import {Row, Col, Tooltip, Layout} from 'antd'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import molexLogo from './../../assests/images/molex_white.png'
import {LeftOutlined  , RollbackOutlined,FontColorsOutlined} from '@ant-design/icons'
import { render } from '@testing-library/react'
const {Header} = Layout

const mapStateToProps = state => {
	return {
		filters: state.filters,
	};
  };
  

class commonHeader extends React.Component {
render = () => {
	return (
		<Header className={this.props.filters.company} style={{position: 'fixed', padding: '0 10px', zIndex: 1, width: '100%', height: '55px', lineHeight: '55px'}}>
			<Row align="middle">
			<Col xs={2} sm={2} md={1} lg={1} xl={1}>
					<Link to={`/`}>
						<Tooltip title='Back'>
							<LeftOutlined   style={{color: 'white', fontSize: 'large'}} />
						</Tooltip>
					</Link>
				</Col>
				<Col xs={6} sm={6} md={3} lg={3} xl={3}>
					<div style={{display:'flex', alignItems:'center'}}>
						<FontColorsOutlined style={{color: 'white', fontSize:'35px'}} />
							<span className='logoTitlte'>
								cronyms
							</span>
						</div>
				</Col>
				
			</Row>
		</Header>
	)
}
}


export default connect(mapStateToProps)(commonHeader);






