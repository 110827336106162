import React from 'react'
import {Row, Col, Icon, Layout} from 'antd'
import {connect} from 'react-redux'
import molexLogo from './../assests/images/molex_white.png'
import HomeListing from './home/index'
import {isAuthenticated, getAuthUrl} from '../utils/auth'

import AddAcronym from './add_Aronym/index'
import ViewAcronym from './view_Aronym/index'
import CommonHeader from './common_header/commonHeader'

const {Header, Content, Footer} = Layout

// REDUX ===========================
const mapStateToProps = state => {
	return {}
}

const mapDispatchToProps = {}

class Asronym extends React.Component {
	componentDidMount = () => {
		if (isAuthenticated() === false) window.location = getAuthUrl('/')
	}
	render = () => {
		return (
			<div>
				<Layout>
					{/* <Header style={{position: 'fixed', padding: '0 10px', zIndex: 1, width: '100%', background: '#e30536',height:'45px',lineHeight:'45px'}}>
						<Row>
							<Col xs={8} sm={8} md={8} lg={4} xl={4}>
								<img style={{width: '60%'}} src={molexLogo} alt='' />
							</Col>
						</Row>
					</Header> */}
					<Content className='site-layout' style={{background: 'white'}}>
						<HomeListing history={this.props.history} />
					</Content>
				</Layout>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Asronym)
