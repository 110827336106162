import React from 'react'
import {Redirect} from 'react-router-dom'
import {processAuthResults} from '../../utils/auth'
import {get, userInfo} from '../../utils/https'
import {message, Spin} from 'antd'
// import { shareUsage } from '../../utils/api_helper'

export default class AuthUser extends React.Component {
	constructor(props) {
		super(props)
		let redirectTo = '/'

		this.state = {
			loading: true,
			redirectTo: processAuthResults(window.location.href),
			userCompany: undefined,
		}
	}

	componentDidMount = () => {
		get(userInfo, (response, error) => {
			if (error) {
				message.error('Sorry Something Went Wrong App Did not Load...')
			} else {
				const email = response.data.sub
				const domain = email.substring(email.lastIndexOf('@') + 1)
				localStorage.setItem('company', response.data.company)
				localStorage.setItem('firstName', response.data.firstName)
				localStorage.setItem('isSupervisor', response.data.isSupervisor)
				localStorage.setItem('lastName', response.data.lastName)
				localStorage.setItem('networkID', response.data.networkID)
				localStorage.setItem('personnelNumber', response.data.personnelNumber)
				localStorage.setItem('preferredName', response.data.preferredName)
				localStorage.setItem('sub', response.data.sub)
				localStorage.setItem('title', response.data.title)
				localStorage.setItem('userPrincipalName', response.data.userPrincipalName)

				switch (domain) {
					case 'molex.com':
						localStorage.setItem('userCompany', 'Molex')
						break
					case 'fhr.com':
						localStorage.setItem('userCompany', 'Flint Hills Resources')
						break
					case 'gp.com':
						localStorage.setItem('userCompany', 'Georgia Pacific')
						break
					case 'invista.com':
						localStorage.setItem('userCompany', 'Invista')
						break
					case 'matadorcattle.com':
						localStorage.setItem('userCompany', 'Matador')
						break

					default:
						localStorage.setItem('userCompany', 'Koch Technology Center')
				}
				this.setState({loading: false})
			}
		})

		// post_Global('https://auth.kochid.com/idp/userinfo.openid', null, (response, error) => {
		// 	if (error) {
		// 		message.error('Sorry Something Went Wrong App Did not Load...')
		// 	} else {

		//         const email = response.sub
		//         const domain = email.substring(email.lastIndexOf("@") +1)
		//         localStorage.setItem('company',response.company)
		//         localStorage.setItem('firstName',response.firstName)
		//         localStorage.setItem('isSupervisor',response.isSupervisor)
		//         localStorage.setItem('lastName',response.lastName)
		//         localStorage.setItem('networkID',response.networkID)
		//         localStorage.setItem('personnelNumber',response.personnelNumber)
		//         localStorage.setItem('preferredName',response.preferredName)
		//         localStorage.setItem('sub',response.sub)
		//         localStorage.setItem('title',response.title)
		//         localStorage.setItem('userPrincipalName',response.userPrincipalName)

		//         switch (domain) {
		//             case 'kochtc.com':
		//                 localStorage.setItem('userCompany',"Koch Technology Center")
		//                break;
		//             case 'molex.com':
		//                 localStorage.setItem('userCompany',"Molex")
		//                 break;
		//             default:
		//                 localStorage.setItem('userCompany',"Idrees")
		//           }
		//         this.setState({loading: false})
		// 	}
		// })

		// on bloody callback save the bloody user bloody object and set the bloody state : loading as bloody false
	}

	render() {
		if (this.state.loading)
			return (
				<div style={{textAlign: 'center', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: '100%'}}>
					<Spin />
				</div>
			)

		return <Redirect to={this.state.redirectTo} />
	}
}
