import { config } from 'dotenv';
config();

const Configuration = {
    environment: process.env.REACT_APP_ENV,
    'authorizationEndpoint': process.env.REACT_APP_AUTHORIZATION_EP,
    'tokenEndpoint': process.env.REACT_APP_TOKEN_EP,
    'clientId': process.env.REACT_APP_CLIENT_ID,
    'redirectUri': process.env.REACT_APP_REDIRECT_EP,
    'logoutEndpoint': process.env.REACT_APP_LOGOUT_EP,
    'scopes': 'employeeInfo openid networkID company'
};

export default Configuration