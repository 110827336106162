import { getAuthToken } from './auth'

// URLs
// const BASE_URL = 'https://0y9zs3bdc1.execute-api.us-east-1.amazonaws.com/qa/'
// const BASE_URL = 'https://kojseyu031.execute-api.us-east-1.amazonaws.com/dev/'
const BASE_URL = 'https://el3uz61ha8.execute-api.us-east-1.amazonaws.com/prod/'

// LINKS
export const abbreviations = 'abbreviations'
export const filters = 'filters'
export const note = 'note'
export const userInfo = 'userInfo'
// export const discoveries = 'guidedDiscovery'
// export const discoveries = 'guidedDiscovery'

const handleUnauthorizedUser = response => {
	if (response.message === 'User unauthorized to use the app.') {
		window.location.href = process.env.REACT_APP_API_EP + 'request_access'
	}
	return response
}



export const get = async (url, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: getAuthToken(),
			'Content-Type': 'application/json',
		},
	})
		.then(response => response.json())
		.then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('GET_FAILED', url, error)
			return handler(null, error)
		})
}

export const post_Global = async (url, body, handler = (response, error) => (response, error)) => {
	return fetch(url, {
		headers: {
			Authorization: "Bearer " + getAuthToken(),
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: null,
	})
		.then(response => response.json())
		.then(response => handler(response))
		.catch(error => {
			console.log('POST_FAILED', url, error)
			return handler(null, error)
		})
}

export const post = async (url, body, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: getAuthToken(),
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(body),
	})
		.then(response => response.json())
		.then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('POST_FAILED', url, error)
			return handler(null, error)
		})
}

export const put = async (url, body, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: getAuthToken(),
			'Content-Type': 'application/json',
		},
		method: 'PUT',
		body: JSON.stringify(body),
	})
		.then(response => response.json())
		.then(handleUnauthorizedUser)
		.then(response => handler(response, null))
		.catch(error => {
			console.log('PUT_FAILED', url, error)
			return handler(null, error)
		})
}

export const delet_e = async (url, handler = (response, error) => (response, error)) => {
	return fetch(BASE_URL + url, {
		headers: {
			Authorization: getAuthToken(),
			'Content-Type': 'application/json',
		},
		method: 'DELETE',
	})
		.then(response => response.json())
		.then(handleUnauthorizedUser)
		.then(response => handler(response))
		.catch(error => {
			console.log('DELETE_FAILED', url, error)
			return handler(null, error)
		})
}


